export const ENUM_JOB_STATUS = {
  draft: 0,
  published: 1,
  closed: 2,
  freeze: 3
}
export const ENUM_JOB_FREEC_STATUS_NUMBER = {
  pending: 0,
  approved: 1,
  rejected: 2,
  expired: 3
}
export const ENUM_COUNT_JOB = {
  published: 'all_published',
  reviewing: 'all_reviewing',
  closed: 'all_closed',
  rejected: 'all_rejected',
  expired: 'all_expired'
}
export const LIST_CLIENT_STATUS = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'De-active' }
  // { id: 3, name: 'Priority' }
]

export const LIST_CLIENT_SOURCES = [
  { id: 0, name: 'Facebook', slug: 'facebook' },
  { id: 1, name: 'Referral', slug: 'referral' },
  { id: 2, name: 'Linkedin', slug: 'linkedin' },
  { id: 3, name: 'Contact Request', slug: 'contact_request' },
  { id: 4, name: 'Platform', slug: 'platform' },
  { id: 5, name: 'Event', slug: 'event' },
  { id: 6, name: 'Job Site', slug: 'job_site' },
  { id: 7, name: 'Email MKT', slug: 'email_mkt' }
]

export const LIST_CLIENT_TYPES = [
  { id: 0, value: 'non_it_and_it', name: 'IT & Non IT' },
  { id: 1, value: 'it', name: 'IT' },
  { id: 2, value: 'non_it', name: 'Non IT' }
]

export const LIST_CONTRACT_DURATION = [
  { id: 0, value: 'one_year', name: '1 year' },
  { id: 1, value: 'two_year', name: '2 years' },
  { id: 2, value: 'infinity', name: 'Infinity Contract' }
]

export const JOB_STATUS_PUBLISH = 'published'
export const JOB_STATUS_DRAFT = 'draft'
export const JOB_STATUS_REVIEWING = 'reviewing'
export const JOB_STATUS_CLOSED = 'closed'
export const JOB_STATUS_REJECTED = 'rejected'
export const JOB_STATUS_EXPIRED = 'expired'
export const JOB_STATUS_FREEZE = 'freeze'

export const JOB_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.published,
    order_by: 'approved_date',
    empty_title: 'There are no published jobs',
    empty_title_vn: 'Không có việc làm đang tuyển'
  },

  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.reviewing,
    order_by: 'created_at',
    empty_title: 'There are no reviewing jobs',
    empty_title_vn: 'Không có việc làm đang duyệt'
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed,
    count: ENUM_COUNT_JOB.closed,
    order_by: 'closed_date',
    empty_title: 'There are no closed jobs',
    empty_title_vn: 'Không có việc làm đã đóng'
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    count: ENUM_COUNT_JOB.rejected,
    order_by: 'rejected_date',
    empty_title: 'There are no rejected jobs',
    empty_title_vn: 'Không có việc làm bị từ chối'
  }
}

export const JOB_ORDERS_STATUS_MAP: {
  [key: string]: { name: string; name_vn: string; freec_status?: number; status: number | number[] }
} = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: [ENUM_JOB_STATUS.published, ENUM_JOB_STATUS.freeze]
  },
  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_FREEZE]: {
    name: 'Freeze',
    name_vn: 'Freeze',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.freeze
  }
}

export const CONSULTANTS_JOB_TYPES = [
  { id: 0, name: 'Non IT' },
  { id: 1, name: 'IT' }
]

export const REJECT_REASON_CANDIDATE = [
  { id: 0, label: 'not_a_fit', name: 'Not a fit', name_vn: 'Không phù hợp' },
  { id: 1, label: 'over_qualify', name: 'Over Qualify', name_vn: 'Vượt yêu cầu' },
  { id: 2, label: 'spam', name: 'Spam', name_vn: 'Spam' },
  { id: 3, label: 'lack_of_skills', name: 'Lack of skills', name_vn: 'Thiếu kinh nghiệm' },
  { id: 4, label: 'hired_elsewhere', name: 'Hired elsewhere', name_vn: 'Đã tuyển cty khác' },
  { id: 5, label: 'over_salary_range', name: 'Over salary range', name_vn: 'Vượt mức lương' },
  { id: 6, label: 'duplication', name: 'Duplication', name_vn: 'Trùng ứng viên' },
  { id: 7, label: 'pending', name: 'Pending', name_vn: 'Tạm ngừng' },
  { id: 8, label: 'not_open', name: 'Not Open', name_vn: 'Chưa mở' }
]

export const INTERVIEW_TYPE = {
  0: { name: 'Phone Interview', name_vn: 'Phỏng vấn qua điện thoại' },
  1: { name: 'Online Interview', name_vn: 'Phỏng vấn online' },
  2: { name: 'On-site Interview', name_vn: 'Phỏng vấn ở office' },
  3: { name: 'Final Interview', name_vn: 'Phỏng vấn vòng cuối' }
}

export const PROBATION_DURATION = [
  {
    id: 0,
    name: 'None',
    name_vn: 'None'
  },
  { id: 1, name: '1 month', name_vn: '1 tháng' },
  { id: 2, name: '2 months', name_vn: '2 tháng' },
  { id: 3, name: '3 months', name_vn: '3 tháng' },
  { id: 4, name: '4 months', name_vn: '4 tháng' },
  { id: 5, name: '5 months', name_vn: '5 tháng' },
  { id: 6, name: '6 months', name_vn: '6 tháng' }
]

export const PLACEMENT_STATUS = [
  { id: 0, name: 'Pending', slug: 'pending' },
  { id: 1, name: 'Onboarding', slug: 'onboarding' },
  { id: 2, name: 'Invoiced', slug: 'invoiced' },
  { id: 3, name: 'Paid', slug: 'paid' },
  { id: 4, name: 'Canceled', slug: 'canceled' },
  { id: 5, name: 'Failed on Probation', slug: 'fail_on_probation' },
  { id: 6, name: 'Replacement', slug: 'replacement' }
]
export const ONBOARDING_STATUS = [
  { id: 0, name: 'Onboarding' },
  { id: 1, name: 'Probation' },
  { id: 2, name: 'Passed' },
  { id: 3, name: 'Failed' }
]
export const OFFER_STATUS = [
  { id: 0, name: 'Waiting for feedback', slug: 'waiting' },
  { id: 1, name: 'Rejected', slug: 'rejected' },
  { id: 2, name: 'Accepted', slug: 'accepted' }
]

export const COMPANY_TYPE = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Client' },
  { id: 2, name: 'Company' }
]

export const JOB_ORDER_STAGE = [
  { id: 1, name: 'Applied' },
  { id: 2, name: 'Sourced' },
  { id: 3, name: 'Screening' },
  { id: 8, name: 'Send to Client' },
  { id: 5, name: 'Interview' },
  { id: 6, name: 'Offer' },
  { id: 7, name: 'Hired' }
]
export const FILTER_JOB_ORDER_STAGE = [
  { id: 1, name: 'Applied', slug: 'applied' },
  { id: 2, name: 'Sourced', slug: 'sourced' },
  { id: 3, name: 'Screening', slug: 'screening' },
  { id: 8, name: 'Send to Client', slug: 'send_to_client' },
  // { id: 4, name: 'Sent to AM' },
  { id: 5, name: 'Interview', slug: 'interview' },
  { id: 6, name: 'Offer', slug: 'offer' },
  { id: 7, name: 'Hired', slug: 'hired' }
]

export const JOB_POST_STAGE = [
  { id: 1, name: 'Applied', name_vn: 'Ứng tuyển' },
  { id: 3, name: 'Screening', name_vn: 'Lọc hồ sơ' },
  { id: 5, name: 'Interview', name_vn: 'Phỏng vấn' },
  { id: 6, name: 'Offer', name_vn: 'Đề nghị làm việc' },
  { id: 7, name: 'Hired', name_vn: 'Đã tuyển' }
]

export const TASK_TYPE = [
  {
    id: 0,
    name: 'To-do'
  },
  {
    id: 1,
    name: 'Call'
  },
  {
    id: 2,
    name: 'Email'
  },
  {
    id: 3,
    name: 'Evaluation'
  }
]

export const TASK_REMINDER = [
  {
    id: 0,
    name: 'No reminder'
  },
  {
    id: 0.25,
    name: '15 mins'
  },
  {
    id: 0.5,
    name: '30 mins'
  },
  {
    id: 1,
    name: '1 hour'
  },
  {
    id: 2,
    name: '2 hours'
  }
]

export const INTERVIEW_REMINDER = [
  { id: 0, value: 'no_reminder', name: 'No Reminder' },
  { id: 1, value: 'fifteen_minutes', name: '15 minutes before' },
  { id: 2, value: 'thirty_minutes', name: '30 minutes before' },
  { id: 3, value: 'fortyfive_minutes', name: '45 minutes before' },
  { id: 4, value: 'one_hour', name: '1 hour before' },
  { id: 5, value: 'two_hour', name: '2 hours before' },
  { id: 6, value: 'one_day', name: '1 day before' }
]

export const ENABLE_ADS_FILLTER = [
  {
    id: 0,
    name: 'All'
  },
  {
    id: 1,
    name: 'Enable Ads'
  },
  {
    id: 2,
    name: 'Block Ads'
  }
]

export const TASK_STATUS = [
  {
    id: 0,
    name: 'Open'
  },
  {
    id: 1,
    name: 'Completed'
  }
]

export const INTERVIEW_ARRAY_TYPE = [
  {
    id: 0,
    name: 'Phone Interview'
  },
  {
    id: 1,
    name: 'Online Interview'
  },
  {
    id: 2,
    name: 'On-site Interview'
  },
  {
    id: 3,
    name: 'Final Interview'
  }
]
export const INTERVIEW_DURATION = [
  {
    id: 0.25,
    name: '00:15'
  },
  {
    id: 0.5,
    name: '00:30'
  },
  {
    id: 0.75,
    name: '00:45'
  },
  {
    id: 1,
    name: '01:00'
  },
  {
    id: 1.25,
    name: '01:15'
  },
  {
    id: 1.5,
    name: '01:30'
  },
  {
    id: 1.75,
    name: '01:45'
  },
  {
    id: 2,
    name: '02:00'
  },
  {
    id: 2.25,
    name: '02:15'
  },
  {
    id: 2.5,
    name: '02:30'
  },
  {
    id: 2.75,
    name: '02:45'
  },
  {
    id: 3,
    name: '03:00'
  }
]
export const JOB_ORDER_TYPES = [
  { id: 0, name: 'Non-IT' },
  { id: 1, name: 'IT' }
]
export const COMPANY_JOB_ORDERS_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed,
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved
  }
}

export const CLIENT_CONTRACT_STATUS = [
  { id: 1, name: 'Agreed' },
  { id: 2, name: 'Signing' },
  { id: 3, name: 'Collected' },
  { id: 4, name: 'Cancel' }
]

export const TIME_EXPIRED_SHARE_CV = [
  { name: '30 days', name_vn: '30 ngày', value: 30 },
  { name: '45 days', name_vn: '45 ngày', value: 45 },
  { name: '60 days', name_vn: '60 ngày', value: 60 }
]
export const CLIENT_REPORT_TABLE = 'CLIENT'
export const CANDIDATE_NOTE_REPORT_TABLE = 'CANDIDATE_NOTE'
export const JOB_ORDER_REPORT_TABLE = 'JOB_ORDER'
export const JOB_PRIORITY_REPORT_TABLE = 'JOB_PRIORITY'
export const PROFILE_REPORT_TABLE = 'PROFILE'
export const VERIFIED_PROFILE_REPORT_TABLE = 'VERIFIED'
export const APPLICANTS_MATCHING_REPORT_TABLE = 'APPLICANTS_MATCHING'
export const APPLICANTS_SOURCED_REPORT_TABLE = 'APPLICANTS_SOURCED'
export const SENT_TO_AM_REPORT_TABLE = 'SENT_TO_AM'
export const SENT_TO_CLIENT_REPORT_TABLE = 'SENT_TO_CLIENT'
export const INTERVIEW_REPORT_TABLE = 'INTERVIEW'
export const OFFER_REPORT_TABLE = 'OFFER'
export const FINAL_INTERVIEW_REPORT_TABLE = 'FINAL_INTERVIEW'
export const COMPLETED_INTERVIEW_REPORT_TABLE = 'C_INTERVIEW'
export const PLACEMENT_REPORT_TABLE = 'PLACEMENT'
export const REVENUE_REPORT_TABLE = 'REVENUE'
export const PAID_REPORT_TABLE = 'PAID'

export const SOURCE_PROFILE = [
  { id: 0, name: 'LinkedIn' },
  { id: 1, name: 'Referral' },
  { id: 2, name: 'Facebook' },
  { id: 3, name: 'Freec' },
  { id: 4, name: 'Careerbuilder' },
  { id: 5, name: 'Email' },
  { id: 6, name: 'Researcher' },
  { id: 7, name: 'Job site' },
  { id: 8, name: 'Github' },
  { id: 9, name: 'Other' }
]
export const LIST_ROLE_ACCOUNT_CONSULTANT = [
  { id: 9, name: 'Admin', key: 'consultant_admin' },
  { id: 10, name: 'Member', key: 'consultant_member' }
]
export const ACTIVE_TAB = 'ACTIVE'
export const INVITATION_TAB = 'INVATATION'
export const DEACTIVE_TAB = 'DEACTIVE'

export const LIST_DEPARTMENT_USER = [
  { id: null, name: 'None', name_filter: 'All' },
  {
    id: 0,
    name: 'Non IT',
    name_filter: 'Non IT'
  },
  {
    id: 1,
    name: 'IT',
    name_filter: 'IT'
  }
]
export const LIST_DEPARTMENT_USER_REMOVE_NONE = [
  {
    id: 0,
    name: 'Non IT'
  },
  {
    id: 1,
    name: 'IT'
  }
]
export const LIST_POSITION_USER = [
  {
    id: 0,
    name: 'Intern',
    order: 12
  },
  {
    id: 1,
    name: 'Consultant',
    order: 4
  },
  {
    id: 2,
    name: 'Principal Consultant',
    order: 2
  },
  {
    id: 3,
    name: 'Team Lead',
    order: 1
  },
  {
    id: 4,
    name: 'BD',
    order: 6
  },
  {
    id: 5,
    name: '360 Consultant',
    order: 3
  },
  {
    id: 6,
    name: 'Trainer',
    order: 8
  },
  {
    id: 7,
    name: 'AM',
    order: 5
  },
  {
    id: 8,
    name: 'BD/AM',
    order: 7
  },
  {
    id: 9,
    name: 'Sales Admin',
    order: 9
  },
  {
    id: 10,
    name: 'Acting Consultant',
    order: 10
  },
  {
    id: 11,
    name: 'Talent Sourcing',
    order: 11
  },
  { id: 12, name: 'Manager', order: 0 }
]
export const MAP_POSITION_USER = LIST_POSITION_USER.reduce((_m, item) => ({ ..._m, [item.id]: item.name }), [])

export const JOB_ORDERS_STATUS = [
  {
    id: 'published',
    name: 'Published',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published
  },
  {
    id: 'reviewing',
    name: 'Reviewing',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published
  },

  {
    id: 'closed',
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed
  },
  {
    id: 'rejected',
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    status: ENUM_JOB_STATUS.published
  }
]
export const LIST_INTERVIEW_TYPE = [
  { id: 0, name: 'Phone Interview' },
  { id: 1, name: 'Online Interview' },
  { id: 2, name: 'On-site Interview' },
  { id: 3, name: 'Final Interview' }
]
export const INTERVIEW_TAB = [
  {
    id: 0,
    key: 'all',
    name: 'All'
  },
  {
    id: 1,
    key: 'upcoming',
    name: 'Upcoming'
  },
  {
    id: 2,
    key: 'feedback_due',
    name: 'Feedback due'
  },
  {
    id: 3,
    key: 'completed',
    name: 'Completed'
  },
  {
    id: 4,
    key: 'cancelled',
    name: 'cancelled'
  }
]
export const ROLE_PROFILE = {
  0: 'recruiter_admin',
  1: 'recruiter_member',
  2: 'consultant_admin',
  3: 'consultant_member',
  4: 'admin_super_admin',
  5: 'admin_member'
}
export const LIST_YEAR_OF_EXP = [
  { id: 0, name: 'under 1 year' },
  { id: 1, name: '1 year' },
  { id: 2, name: '2 years' },
  { id: 3, name: '3 years' },
  { id: 4, name: '4 years' },
  { id: 5, name: '5 years' },
  { id: 6, name: '6 years' },
  { id: 7, name: '7 years' },
  { id: 8, name: '8 years' },
  { id: 9, name: '9 years' },
  { id: 10, name: '10 years' },
  { id: 11, name: 'over 10 years' }
]
export const MAP_YEAR_OF_EXP_ID_MAP = {
  under_1_year: { name: 'Under 1 year' },
  '1_year': { name: '1 year' },
  '2_years': { name: '2 years' },
  '3_years': { name: '3 years' },
  '4_years': { name: '4 years' },
  '5_years': { name: '5 years' },
  '6_years': { name: '6 years' },
  '7_years': { name: '7 years' },
  '8_years': { name: '8 years' },
  '9_years': { name: '9 years' },
  '10_years': { name: '10 years' },
  over_10_years: { name: 'Over 10 years' }
}
export const MAP_YEAR_OF_EXP_ID_MAP_NUMBER = {
  0: { name: 'Under 1 year' },
  1: { name: '1 year' },
  2: { name: '2 years' },
  3: { name: '3 years' },
  4: { name: '4 years' },
  5: { name: '5 years' },
  6: { name: '6 years' },
  7: { name: '7 years' },
  8: { name: '8 years' },
  9: { name: '9 years' },
  10: { name: '10 years' },
  11: { name: 'Over 10 years' }
}
export const FILTER_SALARY_ENUM = [
  {
    id: 1,
    name: 'Under 500$',
    name_vn: 'Dưới 500$',
    min_salary: 0,
    max_salary: 500
  },
  {
    id: 2,
    name: '500-1000$',
    name_vn: '500-1000$',
    min_salary: 500,
    max_salary: 1000
  },
  {
    id: 3,
    name: '1000-1500$',
    name_vn: '1000-1500$',
    min_salary: 1000,
    max_salary: 1500
  },
  {
    id: 4,
    name: '1500-2000$',
    name_vn: '1500-2000$',
    min_salary: 1500,
    max_salary: 2000
  },
  {
    id: 5,
    name: '2000-3000$',
    name_vn: '2000-3000$',
    min_salary: 2000,
    max_salary: 3000
  },
  {
    id: 6,
    name: 'Over 3000$',
    name_vn: 'Trên 3000$',
    min_salary: 3000
  }
]
